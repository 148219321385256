.Message {
	min-width: 200px;
	max-width: none;
	width: calc(100% - 150px);
	height: 100%;
	margin: 1rem 75px;
	padding: 1rem;
	text-align: center;
	border: 1px solid var(--primary_100);
	opacity: 1;
	transform: scaleY(1);
	border-radius: 3px;
	background-color: var(--primary_40);
	display: grid;
	grid-template-columns: 40px 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 'button message';
	place-items: center; /* align justify */
	overflow: hidden;

	p {
		text-align: center;
		width: 90%;
		max-width: 600px;
		margin: 0;
	}

	&,
	* {
		transition: all 0.4s, height 0s 0.4s, margin 0s 0.4s, padding 0s 0.4s;
	}
}

.hideMessage {
	height: 0 !important;
	padding: 0 !important;
	margin: 0 75px !important;
	transform: translateY(-100%);
	opacity: 0;
}
