@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import-normalize;

*,
*:before,
*:after,
html {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
}

body {
	background-color: #333533;
	color: #d9d9d9;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
}

h1 {
	font-size: var(--h1);
	text-align: center;
	width: 90%;
	margin: 2rem auto;
}
h2 {
	font-size: var(--h2);
	text-align: center;
	margin: 2rem auto;
}
h3 {
	font-size: var(--h3);
	text-align: left;
}
h4 {
	font-size: var(--h4);
}
h5 {
	font-size: var(--h5);
}
h6 {
	font-size: var(--h6);
}
div {
	font-size: var(--body);
}
p {
	font-size: var(--body);
	width: 90%;
	max-width: 600px;
	margin: 2rem auto;
	line-height: 1.5rem;
}

/* shows focus ring or underline for keyboard users
See FocusRing.tsx & FocusLine.tsx */
button, a, summary {
	position: relative;
	&:focus {
		outline: 0;
		.focus-ring {
			opacity: 1;
			top: -0.25rem;
			left: -0.25rem;
			width: calc(100% + 0.5rem);
			height: calc(100% + 0.5rem);
			border: 2px solid var(--pop);
		}
		.focus-line {
			opacity: 1;
			top: -0.25rem;
			left: -0.25rem;
			width: calc(100% + 0.5rem);
			height: calc(100% + 0.5rem);
			border-bottom: 2px solid var(--pop);
		}
	}
}

.App {
	outline: none;
	&:focus {
		outline: none;
	}
}

.button {
	display: block;
	border: none;
	outline: none;
	margin: 0;
	text-decoration: none;
	background-color: var(--primary_20);
	color: var(--primary_100);
	cursor: pointer;
	text-align: center;
	-webkit-appearance: none;
	-moz-appearance: none;
	box-shadow: none;
	padding: 0.75rem;
	border-radius: 1px;
	margin: 2px;
	font-size: 1.1rem;

	&:active {
		background-color: var(--primary_40);
	}
	&:focus {
		outline: 0;
	}
}

.ChiRho {
	height: 75px;
	width: 75px;
}
