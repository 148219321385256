.FocusRing {
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  top: -1rem;
  left: -1rem;
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
  border: 1px solid var(--pop);
  border-radius: 5px;
  pointer-events: none;
  transition: '0.2s ease';
}

.rounded {
  border-radius: 50%;
}