.Transition {
	position: absolute;
	top: 0;
	left: 0;
	transition: 0.1s left ease-out;
	width: 100%;
}

.menuOpen {
	left: -150px;
	overflow-y: hidden;
}
