.outerContainer {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	flex: none;
	justify-content: center;
	align-items: center;
}
.innerContainer {
	width: 100px;
	height: 100px;
	position: relative;
}
.chiRho {
	width: 75px;
	height: 75px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.loadingImg {
	transform-origin: center center;
	width: 115px;
	height: 115px;
	position: absolute;
	left: 50%;
	top: 50%;
	animation: spin 4s linear infinite;
}
.loadingParagraph {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 100%;
}

@keyframes spin {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
