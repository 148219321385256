.ThemeGeneral {
	--h1: 2rem;
	--h2: 1.75rem;
	--h3: 1.5rem;
	--h4: 1.3rem;
	--h5: 1.25rem;
	--h6: 1.2rem;
	--body: 1.1rem;
	--small: 0.75rem;
}

.ThemeDark {
	--primary_100: #f1f1f1;
	--primary_90: #dbdbdb;
	--primary_80: #b8b8b8;
	--primary_70: #868686;
	--primary_60: #5f5f5f;
	--primary_50: #494949;
	--primary_40: #404040;
	--primary_30: #333533;
	--primary_20: #242423;
	--primary_10: #202020;
	--primary_0: #000000;

	--pop: #2f42bc;
	--red: red;
}

.ThemeLight {
	--primary_100: #000000;
	--primary_90: #202020;
	--primary_80: #242423;
	--primary_70: #333533;
	--primary_60: #404040;
	--primary_50: #494949;
	--primary_40: #5f5f5f;
	--primary_30: #868686;
	--primary_20: #b8b8b8;
	--primary_10: #dbdbdb;
	--primary_0: #f1f1f1;

	--pop: #2f42bc;
	--red: red;
}