.CloseButton {
	position: relative;
	width: 30px;
	height: 30px;
	display: flex;
	border-radius: 3px;
}

.spanContainer {
	height: 100%;
	width: 100%;
	position: relative;
}

.span {
	display: block;
	position: absolute;
	height: 1px;
	width: 15px;
	left: 50%;
	top: 50%;
	background-color: var(--primary_100);
}

.span1 {
	composes: span;
	transform: translate(-50%, -50%) rotate(45deg);
}

.span2 {
	composes: span;
	transform: translate(-50%, -50%) rotate(-45deg);
}
