.button {
	position: fixed;
	display: block;
	z-index: 100;
	border: none;
	outline: none;
	text-decoration: none;
	background-color: var(--primary_20);
	color: var(--primary_100);
	cursor: pointer;
	text-align: center;
	-webkit-appearance: none;
	-moz-appearance: none;
	box-shadow: none;
	border-radius: 1px;
	font-size: 1.1rem;
	border-radius: 3px;

	top: 20px;
	right: 20px;
	width: 45px;
	height: 45px;

	&:active {
		background-color: var(--primary_40);
	}

	transition: transform 0.1s ease-out;
}

.menuOpen {
	transform: translateX(-150px);
}

.span {
	position: absolute;
	display: block;
	height: 1px;
	width: 70%;
	left: 15%;
	border-radius: 5px;
	background-color: var(--primary_100);

	transition: transform 0.3s;
}

.span1 {
	composes: span;
	top: 27%;
}

.span1Open {
	transform: translate(1px, 9px) rotate(45deg);
}

.span2 {
	composes: span;
	top: 48.1%;
}

.span2Open {
	opacity: 0;
}

.span3 {
	composes: span;
	top: 70%;
}

.span3Open {
	transform: translate(1px, -10px) rotate(-45deg);
}
