.nav {
	position: fixed;
	display: block;
	z-index: 100;
	top: 0px;
	margin: 0;
	padding: 0;
	left: 100%;
	width: 150px;
	height: 100vh;
	background-color: var(--primary_20);
	transition: transform 0.1s ease-out;

	.chiRhoLink {
		display: block;
		width: fit-content;
		height: fit-content;
		margin: 50px auto;
	}

	img {
		display: block;
	}
}

.ul {
	list-style: none;
	width: 90%;
	margin: auto;
}

.li {
	width: max-content;
	margin: 2rem auto;
}

.link {
	font-size: var(--body);
	margin: 0;
	color: var(--primary_100);
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.menuOpen {
	transform: translateX(-150px);
}
