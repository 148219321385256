@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

/* Document
 * ========================================================================== *//**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}/* Sections
 * ========================================================================== *//**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
 * Add the correct display in IE.
 */main {
  display: block;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//**
 * Hide the overflow in IE.
 */svg:not(:root) {
  overflow: hidden;
}/* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * Correct the padding in Firefox.
 */fieldset {
  padding: 0.35em 0.75em 0.625em;
}/**
 * Show the overflow in Edge 18- and IE.
 */input {
  overflow: visible;
}/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//**
 * Add the correct display in IE.
 */template {
  display: none;
}/* User interaction
 * ========================================================================== */*,*:before,*:after,html{padding:0;margin:0;box-sizing:border-box;font-family:'Open Sans', sans-serif}body{background-color:#333533;color:#d9d9d9;overflow-x:hidden}h1,h2,h3,h4,h5,h6{font-weight:400}h1{font-size:var(--h1);text-align:center;width:90%;margin:2rem auto}h2{font-size:var(--h2);text-align:center;margin:2rem auto}h3{font-size:var(--h3);text-align:left}h4{font-size:var(--h4)}h5{font-size:var(--h5)}h6{font-size:var(--h6)}div{font-size:var(--body)}p{font-size:var(--body);width:90%;max-width:600px;margin:2rem auto;line-height:1.5rem}button,a,summary{position:relative}button:focus,a:focus,summary:focus{outline:0}button:focus .focus-ring,a:focus .focus-ring,summary:focus .focus-ring{opacity:1;top:-0.25rem;left:-0.25rem;width:calc(100% + 0.5rem);height:calc(100% + 0.5rem);border:2px solid var(--pop)}button:focus .focus-line,a:focus .focus-line,summary:focus .focus-line{opacity:1;top:-0.25rem;left:-0.25rem;width:calc(100% + 0.5rem);height:calc(100% + 0.5rem);border-bottom:2px solid var(--pop)}.App{outline:none}.App:focus{outline:none}.button{display:block;border:none;outline:none;margin:0;text-decoration:none;background-color:var(--primary_20);color:var(--primary_100);cursor:pointer;text-align:center;-webkit-appearance:none;-moz-appearance:none;box-shadow:none;padding:0.75rem;border-radius:1px;margin:2px;font-size:1.1rem}.button:active{background-color:var(--primary_40)}.button:focus{outline:0}.ChiRho{height:75px;width:75px}

.Menu_nav__3IWQA{position:fixed;display:block;z-index:100;top:0px;margin:0;padding:0;left:100%;width:150px;height:100vh;background-color:var(--primary_20);transition:-webkit-transform 0.1s ease-out;transition:transform 0.1s ease-out;transition:transform 0.1s ease-out, -webkit-transform 0.1s ease-out}.Menu_nav__3IWQA .Menu_chiRhoLink__3djvS{display:block;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin:50px auto}.Menu_nav__3IWQA img{display:block}.Menu_ul__2prWi{list-style:none;width:90%;margin:auto}.Menu_li__Nx2on{width:-webkit-max-content;width:max-content;margin:2rem auto}.Menu_link__PwcJp{font-size:var(--body);margin:0;color:var(--primary_100);text-decoration:none}.Menu_link__PwcJp:hover{text-decoration:underline}.Menu_menuOpen__1xDiE{-webkit-transform:translateX(-150px);transform:translateX(-150px)}

.FocusRing_FocusRing__13NAD{opacity:0;position:absolute;right:0;bottom:0;top:-1rem;left:-1rem;width:calc(100% + 2rem);height:calc(100% + 2rem);border:1px solid var(--pop);border-radius:5px;pointer-events:none;transition:'0.2s ease'}.FocusRing_rounded__wmV59{border-radius:50%}

.Links_Link__3H4bq{position:relative}.Links_Link__3H4bq:focus{outline:0}.Links_LinkFocus__wfcMb:focus,.Links_LinkFocus__wfcMb:focus-within{text-decoration:underline;text-decoration-thickness:3px !important;-webkit-text-decoration-color:var(--pop) !important;text-decoration-color:var(--pop) !important}

.MenuButton_button__TvK0q{position:fixed;display:block;z-index:100;border:none;outline:none;text-decoration:none;background-color:var(--primary_20);color:var(--primary_100);cursor:pointer;text-align:center;-webkit-appearance:none;-moz-appearance:none;box-shadow:none;border-radius:1px;font-size:1.1rem;border-radius:3px;top:20px;right:20px;width:45px;height:45px;transition:-webkit-transform 0.1s ease-out;transition:transform 0.1s ease-out;transition:transform 0.1s ease-out, -webkit-transform 0.1s ease-out}.MenuButton_button__TvK0q:active{background-color:var(--primary_40)}.MenuButton_menuOpen__iPXHv{-webkit-transform:translateX(-150px);transform:translateX(-150px)}.MenuButton_span__3Suxb{position:absolute;display:block;height:1px;width:70%;left:15%;border-radius:5px;background-color:var(--primary_100);transition:-webkit-transform 0.3s;transition:transform 0.3s;transition:transform 0.3s, -webkit-transform 0.3s}.MenuButton_span1__3Pi1l{top:27%}.MenuButton_span1Open__2MSzz{-webkit-transform:translate(1px, 9px) rotate(45deg);transform:translate(1px, 9px) rotate(45deg)}.MenuButton_span2__6gKgq{top:48.1%}.MenuButton_span2Open__1vTUW{opacity:0}.MenuButton_span3__Jie4g{top:70%}.MenuButton_span3Open__1_uaZ{-webkit-transform:translate(1px, -10px) rotate(-45deg);transform:translate(1px, -10px) rotate(-45deg)}

.Transition_Transition__1xcVw{position:absolute;top:0;left:0;transition:0.1s left ease-out;width:100%}.Transition_menuOpen__1kXSF{left:-150px;overflow-y:hidden}

.Message_Message__2fF6f{min-width:200px;max-width:none;width:calc(100% - 150px);height:100%;margin:1rem 75px;padding:1rem;text-align:center;border:1px solid var(--primary_100);opacity:1;-webkit-transform:scaleY(1);transform:scaleY(1);border-radius:3px;background-color:var(--primary_40);display:grid;grid-template-columns:40px 1fr;grid-template-rows:1fr;grid-template-areas:'button message';place-items:center;overflow:hidden}.Message_Message__2fF6f p{text-align:center;width:90%;max-width:600px;margin:0}.Message_Message__2fF6f,.Message_Message__2fF6f *{transition:all 0.4s, height 0s 0.4s, margin 0s 0.4s, padding 0s 0.4s}.Message_hideMessage__3Maq_{height:0 !important;padding:0 !important;margin:0 75px !important;-webkit-transform:translateY(-100%);transform:translateY(-100%);opacity:0}

.CloseButton_CloseButton__kiVLC{position:relative;width:30px;height:30px;display:flex;border-radius:3px}.CloseButton_spanContainer__2-R0R{height:100%;width:100%;position:relative}.CloseButton_span__2BUwf{display:block;position:absolute;height:1px;width:15px;left:50%;top:50%;background-color:var(--primary_100)}.CloseButton_span1__3y3OT{-webkit-transform:translate(-50%, -50%) rotate(45deg);transform:translate(-50%, -50%) rotate(45deg)}.CloseButton_span2__L_v-b{-webkit-transform:translate(-50%, -50%) rotate(-45deg);transform:translate(-50%, -50%) rotate(-45deg)}

.Loading_outerContainer__2wxU4{position:absolute;top:0;left:0;height:100vh;width:100vw;display:flex;flex:none;justify-content:center;align-items:center}.Loading_innerContainer__rV3AZ{width:100px;height:100px;position:relative}.Loading_chiRho__25svX{width:75px;height:75px;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}.Loading_loadingImg__k3Ljo{-webkit-transform-origin:center center;transform-origin:center center;width:115px;height:115px;position:absolute;left:50%;top:50%;-webkit-animation:Loading_spin__2KgBV 4s linear infinite;animation:Loading_spin__2KgBV 4s linear infinite}.Loading_loadingParagraph__1PnIQ{position:absolute;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);top:100%}@-webkit-keyframes Loading_spin__2KgBV{0%{-webkit-transform:translate(-50%, -50%) rotate(0deg);transform:translate(-50%, -50%) rotate(0deg)}100%{-webkit-transform:translate(-50%, -50%) rotate(360deg);transform:translate(-50%, -50%) rotate(360deg)}}@keyframes Loading_spin__2KgBV{0%{-webkit-transform:translate(-50%, -50%) rotate(0deg);transform:translate(-50%, -50%) rotate(0deg)}100%{-webkit-transform:translate(-50%, -50%) rotate(360deg);transform:translate(-50%, -50%) rotate(360deg)}}

.ErrorBoundary_outerWrapper__2BLif{height:100vh;width:100vw;display:flex;flex:none;justify-content:center;align-items:center}.ErrorBoundary_innerWrapper__3pwcX{width:90%;max-width:600px}.ErrorBoundary_Link__C7bSC{color:var(--primary_100)}

.Themes_ThemeGeneral__3ENJJ{--h1: 2rem;--h2: 1.75rem;--h3: 1.5rem;--h4: 1.3rem;--h5: 1.25rem;--h6: 1.2rem;--body: 1.1rem;--small: 0.75rem}.Themes_ThemeDark__1sjFb{--primary_100: #f1f1f1;--primary_90: #dbdbdb;--primary_80: #b8b8b8;--primary_70: #868686;--primary_60: #5f5f5f;--primary_50: #494949;--primary_40: #404040;--primary_30: #333533;--primary_20: #242423;--primary_10: #202020;--primary_0: #000000;--pop: #2f42bc;--red: red}.Themes_ThemeLight__1MtPh{--primary_100: #000000;--primary_90: #202020;--primary_80: #242423;--primary_70: #333533;--primary_60: #404040;--primary_50: #494949;--primary_40: #5f5f5f;--primary_30: #868686;--primary_20: #b8b8b8;--primary_10: #dbdbdb;--primary_0: #f1f1f1;--pop: #2f42bc;--red: red}

