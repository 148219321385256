.Link {
  position: relative;

  &:focus {
    outline: 0;
  }
}

.LinkFocus:focus, .LinkFocus:focus-within {
  text-decoration: underline;
  text-decoration-thickness: 3px !important;
  text-decoration-color: var(--pop) !important;
}